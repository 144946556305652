:root {
  /* Colors */
  --battleship-grey: #6e757c;
  --black: #000000;
  --black-10: rgba(0, 0, 0, 0.1);
  --black-15: rgba(0, 0, 0, 0.15);
  --black-17: rgba(0, 0, 0, 0.17);
  --black-20: rgba(0, 0, 0, 0.2);
  --black-30: rgba(0, 0, 0, 0.3);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-50: rgba(0, 0, 0, 0.5);
  --black-60: rgba(0, 0, 0, 0.6);
  --black-80: rgba(0, 0, 0, 0.8);
  --brown-grey: #999;
  --bluey-grey-30: rgba(164, 165, 177, 0.3);
  --brown-grey-40: rgba(153, 153, 153, 0.4);
  --bluey-grey-50: rgba(164, 165, 177, 0.5);
  --bluey-grey-80: rgba(164, 165, 177, 0.8);
  --brownish-grey: #727272;
  --cool-grey: #a0a4a9;
  --dark-green: #1a3c34;
  --dodger-blue: #386bfc;
  --gin: #e4ede8;
  --grey-beard: #e5e5e5;
  --grey: #c4c4c4;
  --grey-day: #b3b3b3;
  --greyish: #b6b6b6;
  --gunmetal: #4b4f54;
  --gunmetal-10: rgba(75, 79, 84, 0.1);
  --gunmetal-20: rgba(75, 79, 84, 0.2);
  --gunmetal-50: rgba(75, 79, 84, 0.5);
  --ice: #e6eeec;
  --ice-blue-80: rgba(235, 241, 242, 0.8);
  --ice-blue: #ebf1f2;
  --khaki-20: rgba(151, 166, 105, 0.2);
  --khaki-40: rgba(151, 166, 105, 0.4);
  --light-grey: #e8e8e8;
  --light-grey-blue: #aaadb2;
  --light-grey-blue-50: rgba(176, 177, 190, 0.5);
  --maize: #fcde4a;
  --maize-30: rgba(252, 222, 74, 0.3);
  --maize-60: rgba(252, 222, 74, 0.6);
  --pale-grey: #f4f4f8;
  --pale-red: #e84745;
  --pale-red-30: rgba(232, 71, 69, 0.3);
  --pale-red-60: rgba(232, 71, 69, 0.6);
  --pale-sky-blue: #bcf1ff;
  --powder-blue-60: rgba(183, 214, 255, 0.6);
  --salmon: #fc8773;
  --seafoam-blue-60: rgba(89, 227, 176, 0.6);
  --silver: #bdbdbd;
  --teal-green: #3ba38c;
  --transparent: rgba(0, 0, 0, 0);
  --very-light-pink: #cccccc;
  --very-light-pink-2: #c8c8c8;
  --very-light-pink-3: #f1f1f1;
  --very-light-pink-4: #efeff1;
  --very-light-pink-5: #ececec;
  --very-light-pink-6: #d1d1d1;
  --very-light-pink-7: #efefef;
  --very-light-pink-8: #efeff0;
  --warning-yellow: #ffc900;
  --white-0: rgba(255, 255, 255, 0);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-three: #f8f8f8;
  --white-two: #f6f6f6;
  --white: #ffffff;

  /* Animations */
  --medium-transition-time: 200ms;
  --bounce: cubic-bezier(0.65, 1.8, 0.5, 0.8);

  /* Brand Colors */
  --pax-forest-green: #22462a;
  --pax-forest-green-60: rgba(34, 70, 42, 0.6);
  --pax-lavender: #d4d0e2;
  --pax-lavender-60: rgba(212, 208, 226, 0.6);
  --pax-navy-blue: #1a1e39;
  --pax-navy-blue-60: rgba(26, 30, 57, 0.6);
  --pax-yellow: #ffef00;
  --pax-yellow-60: rgba(255, 239, 0, 0.6);

  /* Connection Modal */
  --connection-modal-default-width: 424px;

  /* Mobile nav bar */
  --mobile-nav-bar-height: 54px;

  /* Pax nav */
  --pax-nav-height: 62px;

  /* Pod Feedback */
  --mobile-pod-feedback-prompt-height: 48px;
  --pod-feedback-prompt-collapsed-width: 80px;
  --pod-feedback-prompt-expanded-width: 300px;
  --pod-feedback-prompt-height: 80px;

  /* Pod Test Results */
  --pod-test-result-content-width: 358px;

  /* Other */
  --banner-content-width: 1096px;
  --banner-height: 44px;
  --connected-pod-card-height: 112px;
  --connected-pod-card-width: 204px;
  --default-modal-width: 420px;
  --device-connection-modal-width: 898px;
  --device-shaking-animation-height: 35vh;
  --experience-modes-large-module-height: 237px;
  --experience-modes-large-module-width: 328px;
  --experience-modes-small-module-size: 156px;
  --location-header-height: 48px;
  --desktop-explore-header-height: 123px;
  --mobile-header-box-shadow: 0px -8px 38px var(--black-17);
  --my-pax-header-font-size-short: 22px;
  --my-pax-header-font-size-tall: 32px;
  --my-pax-header-height-short: 120px;
  --my-pax-header-height-tall: 140px;
  --my-pax-page-content-width: 560px;
  --nav-bar-width: 1440px;
  --page-width: 872px;
  --top-nav-height: 70px;
  --top-pod-card-height: 180px;
  --z-index-location-menu: 1;
  --z-index-modal-header: 1;
  --z-index-pods-filter-modal-buttons: 1;
  --z-index-top-bar: 1;
  --z-index-visualization-page: 2;
  --z-index-modal-closer: 3;
  --z-index-favorite-heart: 5;
  --z-index-explore-recent-searches: 10;
  --z-index-fixed-header: 10;
  --z-index-pod-feedback-prompt: 10;
  --z-index-pod-switcher: 10;
  --z-index-sticky-headers: 10;
  --z-index-toast: 10;
  --z-index-side-bar: 15;
  --z-index-toast-closer: 15;
  --z-index-banner-drawer: 28;
  --z-index-mobile-nav-bar: 29;
  --z-index-modal-overlay: 30; /* left here to document modal behavior */
  --z-index-pax-menu: 31;
}
