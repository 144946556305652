/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * pax.com (monthly pageviews: <50K)
 *
 */

/*
 * Legal Disclaimer for App High licence
 *
 * Licence to use fonts in native, web or hybrid apps, on stationary, mobile or built-in devices like.
 *
 * Should the license download limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following App(s):
 *
 * Perpetual license based on installations/downloads
 * App title PAX
 * For a total of <5m installations/downloads
 *
 */

/*
 * Legal Disclaimer for Online Advertising licence
 *
 * Licence to use fonts in advertising on websites and mobile platforms such as banners, pop-ups, floating ads, video ads, HTML5 ads, eMail newsletters etc.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These fonts are licensed exclusively for:
 *
 * Static or dynamic online advertising
 * For up to 100k monthly ad impressions
 *
 */

/* MediumLL */

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "MediumLL";
  src: url("fonts/MediumLLWeb-Regular.woff2") format("woff2");
}

/* Plaax */

@font-face {
  font-family: "Plaax";
  src: url("fonts/Plaax - 36-Ney-Regular-205TF.woff") format("woff");
}

@font-face {
  font-family: "Plaax";
  font-weight: 300;
  src: url("fonts/Plaax - 26-Ney-Light-205TF.woff") format("woff");
}
